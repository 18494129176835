import Procedure from '@/assets/icons/procedure.svg'
import Appointment from '@/assets/icons/appointment.svg'
import SurgicalProcedure from '@/assets/icons/surgical-procedure.svg'
import Return from '@/assets/icons/return.svg'
import Telemedicine from '@/assets/icons/telemedicine.svg'
import Exam from '@/assets/icons/exam.svg'
import Reschedule from '@/assets/icons/reschedule.svg'
import Fitting from '@/assets/icons/finished-attendance.svg'

export default {
  props: {
    type: String,
  },
  computed: {
    getSvgIcon() {
      return {
        'PROCEDURE': Procedure,
        'APPOINTMENT': Appointment,
        'SURGICAL': SurgicalProcedure,
        'RETURN': Return,
        'TELEMEDICINE': Telemedicine,
        'EXAM': Exam,
        'Reagendamento': Reschedule,
        'Encaixe': Fitting,
      }[this.type] || Procedure
    },
  },
  render() {
    const Component = this.getSvgIcon
    return <Component />
  }
}
