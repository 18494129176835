<template>
  <section id="event-item-card">
    <div class="row content time-filter">
      <div class="col-3 padding-fix">
        <TimeSelect
          required
          v-model="selectedStartTime"
          :key="selectedStartTime"
          :options="possibleHours"
          :startTime="possibleHours[8].value"
          :stringTime="possibleHours[8].value"
          :checkBusyTime="checkBusyTime"
          @input="updateStartTime"
        />
      </div>
      <div class="col-3 padding-fix">
        <TimeSelect
          required
          v-model="selectedEndTime"
          :options="possibleHours"
          :key="selectedEndTime"
          :startTime="possibleHours[18].value"
          :stringTime="possibleHours[18].value"
          :checkBusyTime="checkBusyTime"
          @input="updateEndTime"
        />
      </div>
      <div class="col-6 padding-fix">
        <multiselect
          ref="patient"
          v-model="patient"
          class="patient-name"
          :options="patients"
          track-by="id"
          label="name"
          :showLabels="false"
          :allowEmpty="false"
          :loading="loading"
          :internal-search="false"
          :value="patient"
          @search-change="debounceGetPatients"
          @select="selectPatient"
          placeholder="Buscar paciente"
          :disabled="disabled"
        >
          <template slot="singleLabel" slot-scope="props">
            <div class="patient-name-doctor">
              {{ props.option?.name }}
            </div>
          </template>
          <template slot="option" slot-scope="props">
            <div>
              <div class="patient-name">{{ props.option?.name }}</div>
              <div class="patient-props">
                <div v-if="props.option?.cpf" class="patient-cpf">
                  CPF {{ props.option?.cpf }}
                </div>
              </div>
            </div>
          </template>

          <template slot="placeholder">
            <v-lupa-icon />
            <span class="multiselect__placeholder no-padding">Buscar paciente</span>
          </template>

          <template slot="noOptions">
            <div>Digite para pesquisar seus pacientes...</div>
          </template>
          <template slot="noResult">
            <div>Não encontrado paciente cadastrado com este nome...</div>
          </template>

          <template slot="singleLabel" slot-scope="{ option }">
            {{ option?.name }}
          </template
          >#list-avaliable-hours .content
        </multiselect>
        <a v-if="patient" class="clear" @click="clearPatient()">Limpar seleção</a> 
      </div>
    </div>
    <div id="infinite-list">
      <div
        class="content row fc-content fc-day-content event-scheduled"
        v-for="(data, dataIndex) in this.data"
        :key="dataIndex"
        @click="openAppointmentModal(data.id)"
      >
        <div class="col-1">
          <div class="statu-ret" :class="changeFillColor(data.status)"></div>
        </div>
        <div class="td-30 col-3 pointer">
          <div class="time">
            {{ moment(data.start_datetime).format("HH:mm") }} -
            {{ moment(data.end_datetime).format("HH:mm") }}
          </div>
          <div class="fc-columns">
            <div class="items-row">
              <span v-for="(item, itemIndex) in data.appointment_items" :key="itemIndex">
                <div
                  v-if="item?.clinic_procedure?.type === 'APPOINTMENT'"
                  class="fc-icon mr-1 items item-appointment"
                >
                  <SvgIcon type="APPOINTMENT" />
                </div>
                <div
                  v-if="item?.clinic_procedure?.type === 'PROCEDURE'"
                  class="fc-icon mr-1 items item-procedure"
                >
                  <SvgIcon type="PROCEDURE" />
                </div>
                <div
                  v-if="item?.clinic_procedure?.type === 'SURGICAL'"
                  class="fc-icon mr-1 items item-surgical"
                >
                  <SvgIcon type="SURGICAL" />
                </div>
                <div
                  v-if="item?.clinic_procedure?.type === 'RETURN'"
                  class="fc-icon mr-1 items item-return"
                >
                  <SvgIcon type="RETURN" />
                </div>
                <div
                  v-if="item?.clinic_procedure?.type === 'EXAM'"
                  class="fc-icon mr-1 items item-exam"
                >
                  <SvgIcon type="EXAM" />
                </div>
                <div
                  v-if="item?.clinic_procedure?.type === 'TELEMEDICINE'"
                  class="fc-icon mr-1 items item-telemedicine"
                >
                  <SvgIcon type="TELEMEDICINE" />
                </div>
              </span>
              <div v-if="data?.type === 'Encaixe'" class="fc-icon mr-1 items">
                <SvgIcon type="Encaixe" />
              </div>
              <div v-if="data?.type === 'Reagendamento'" class="fc-icon mr-1 items">
                <SvgIcon type="Reagendamento" />
              </div>
            </div>
          </div>
        </div>
        <div class="td-70 col-7 pointer">
          <div class="item-data">
            <div class="patient-name">
              {{ data.patient?.name }}
            </div>
            <div class="doc-info">
              {{ data.appointment_items[0]?.health_plan.fantasy_name }} <br />
              <span v-if="data?.professional?.name">
                {{
                  data?.professional?.gender === "M"
                    ? "Dr."
                    : data?.professional?.gender === "F"
                    ? "Dra."
                    : ""
                }}
                {{ data.professional?.name }}
              </span>
              <span v-if="data.room?.name">
                {{ data.room?.name }}
              </span>
            </div>
          </div>
        </div>
        <div :id="`sidebar-popover-${dataIndex}`" class="col-1 more-icon help">
          <v-more-info-icon />

          <b-popover
            class="items-container"
            :target="`sidebar-popover-${dataIndex}`"
            triggers="hover"
            placement="bottom"
          >
            <!--         PACIENT DATA  -->
            <p class="mb-3">
              {{ data?.patient?.name }}
              ({{
                data?.patient?.birthday
                  ? `${moment().diff(data?.patient?.birthday, "years")} Anos`
                  : "Sem idade cadastrada"
              }})
              <WhatsAppIcon
                v-if="data?.patient?.cellphone || data?.patient?.cellphone2"
                class="ml-3 cursor-pointer"
                style="cursor: pointer"
                @click="
                  openWhatsApp(data?.patient?.cellphone ?? data?.patient?.cellphone2)
                "
              />
              <br />
              {{ data?.patient?.email ?? "Sem email cadastrado" }}
            </p>
            <p></p>

            <!--         DOCTOR DATA  -->
            <div class="mb-1" v-if="data?.professional?.name">
              <Doctor class="icon" />
              {{
                data?.professional?.gender === "M"
                  ? "Dr."
                  : data?.professional?.gender === "F"
                  ? "Dra."
                  : ""
              }}
              {{ data?.professional?.name }}
            </div>
            <!--        ROOM DATA    -->
            <div class="mb-1" v-if="data?.room?.name">
              <Room class="icon" />
              {{ data?.room?.name }}
            </div>
            <!--        HEALTH PLAN DATA    -->
            <div class="mb-1" v-if="data.appointment_items[0]?.health_plan.fantasy_name">
              <HealthPlan class="icon fix-health" />
              {{ data.appointment_items[0]?.health_plan.fantasy_name }}
            </div>
            <!--        MONEY DATA   -->
            <label class="mb-1">
              <MoneyIcon class="ml-0" />
              {{ parseNumberToMoney(data?.total_value) ?? parseNumberToMoney(0) }}
            </label>
            <!--        PROCEDURES DATA    -->
            <p v-for="item in data?.appointment_items" class="pl-2 pr-4" :key="item.id">
              <b-row>
                <ItemIconHandler :type="item?.item?.type" />
                <span class="mt-1">{{ item?.item?.name ?? item?.item?.name }}</span>
              </b-row>
            </p>
            <div class="mb-1" v-if="data.description && data.description.length > 0">
              <DescriptionIcon class="icon" />
              {{ data.description }}
            </div>
          </b-popover>
        </div>
      </div>
      <div v-show="this.loadingIcon" id="loadingIcon">
        <Loading />
      </div>
    </div>

    <AppointmentModal
      :appointmentId="appointmentId"
      :setAppointmentId="setAppointmentId"
      :id="idModal"
    />
  </section>
</template>
<script>
import { getCurrentClinic } from "@/utils/localStorageManager";
import { BPopover } from "bootstrap-vue";
import MoreInfoIcon from "@/assets/icons/more-info.svg";
import LupaIcon from "@/assets/icons/lupa-left.svg";
import TimeSelect from "@/components/Schedule/TimeSelect";
import SvgIcon from "@/components/SurgeryCenter/SurgicalMap/SvgIcon";
import moment from "moment";
import { debounce } from "lodash";
import { parseNumberToMoney } from '@/utils/moneyHelper'

export default {
  name: "ListEvent",
  components: {
    TimeSelect,
    SvgIcon,
    BPopover,
    "v-more-info-icon": MoreInfoIcon,
    "v-lupa-icon": LupaIcon,
    AppointmentModal: () => import("@/components/AppointmentModal"),
    Room: () => import("@/assets/icons/room.svg"),
    WhatsAppIcon: () => import("@/assets/icons/whatsapp-icon.svg"),
    MoneyIcon: () => import("@/assets/icons/cash.svg"),
    ItemIconHandler: () => import("@/components/Overview/ItemIconHandler"),
    Doctor: () => import("@/assets/icons/new-doctor.svg"),
    HealthPlan: () => import("@/assets/icons/health-plan-light.svg"),
    Loading: () => import("@/assets/icons/loading-dual-transparent.svg"),
    DescriptionIcon: () => import("@/assets/icons/new-description.svg"),
  },
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    date: Object,
    filters: {
      type: Object,
      default: () => ({
        filters: {},
      }),
    },
  },
  data() {
    return {
      idModal: "appointment-modal-sidebar",
      clinic: getCurrentClinic(),
      debounceGetPatients: debounce(this.searchPatient, 300),
      patients: [],
      data: [],
      currentPage: 0,
      nextPage: 1,
      lastPage: 0,
      loadingIcon: true,
      loading: false,
      patient: null,
      appointmentId: null,
      professional: null,
      selectedStartTime: "06:00",
      selectedEndTime: "20:00",
      possibleHours: Array.from({ length: 24 }, (v, i) => {
        const hour = i.toString().padStart(2, "0");
        return { label: `${hour}:00`, value: `${hour}:00` };
      }),
      selectedPatient: null,
    };
  },
  watch: {
    filters: {
      handler() {
        this.getData();
      },
    },
    patient: {
      handler() {
        this.filters.filters.patient = this.patient.name;

        this.getData();
      },
    },
  },
  computed: {},
  mounted() {
    this.getInitialClinicHours();

    const masonry = document.querySelector("#infinite-list");
    masonry.addEventListener("scroll", (e) => {
      if (masonry.scrollTop + masonry.clientHeight >= masonry.scrollHeight) {
        this.loadMorePages(this.nextPage);
      }
    });
  },
  methods: {
    parseNumberToMoney,
    setAppointmentId(id) {
      this.appointmentId = id;
    },
    moment,
    changeFillColor(status) {
      switch (status) {
        case "SCHEDULED":
          return "scheduled";
        case "CONFIRMED":
          return "confirmed";
        case "CONFIRMED_BY_PATIENT":
          return "confirmed";
        case "ATTEND":
          return "attend";
        case "WAITING":
          return "waiting";
        case "MISSED":
          return "missed";
        case "CANCELLED":
          return "cancelled";
        case "CANCELLED_BY_PATIENT":
          return "cancelled";
        case "HAPPENING":
          return "happening";
        case "EXAMINATING":
          return "examinating";
        case "DILATING":
          return "dilating";
        case "FINISHED":
          return "finished";
        case "IN_PRE_CONSULTATION":
          return "in_pre_consultation";
        case "PRE_FINISHED":
          return "pre_finished";
        default:
          return "scheduled";
      }
    },
    clearPatient() {
      this.patient = "";
      this.getData();
    },
    async openAppointmentModal(appointmentId = null) {
      this.appointmentId = appointmentId;

      this.$bvModal.show(this.idModal);
    },
    getInitialClinicHours() {
      try {
        this.api
          .getClinicOpenHours(
            this.clinic.id,
            this.date
              .format("dddd")
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toUpperCase()
          )
          .then((res) => {
            this.selectedStartTime = res.data?.days[0].schedule_config_day_intervals[0].start_time.slice(
              0,
              5
            );
            this.selectedEndTime = res.data?.days[0].schedule_config_day_intervals[0].end_time.slice(
              0,
              5
            );

            this.getData();
          })
          .catch((err) => {
            console.log("Err", err);
          });
      } catch (err) {
        console.log(err);
      }
    },
    getData() {
      try {
        this.loadingIcon = true;

        const parsedDate = new Date(this.date).toISOString();

        this.api
          .getClinicEventsByDate(
            this.clinic.id,
            parsedDate,
            this.selectedStartTime,
            this.selectedEndTime,
            JSON.stringify(this.filters)
          )
          .then(async (res) => {

            this.data = res.data?.data;
            
            if(this.data){
              for await (const item of this.data) {
                item.total_value = 0;
                if (item.appointment_items) {
                  for await (const appointmentItem of item.appointment_items) {
                    item.total_value += appointmentItem.value;
                  }
                }
              }

              this.currentPage = res.data?.current_page;
              this.lastPage = res.data?.last_page;
              this.nextPage++;
              this.loadingIcon = false;
            }
          })
          .catch((err) => {
            console.log("Err", err);
          });
      } catch (err) {
        console.log(err);
      }
    },
    loadMorePages(pageNum) {
      try {
        const parsedDate = new Date(this.date).toISOString();

        if (this.currentPage !== this.lastPage) {
          this.api
            .getClinicEventsByDate(
              this.clinic.id,
              parsedDate,
              this.selectedStartTime,
              this.selectedEndTime,
              JSON.stringify(this.filters),
              pageNum
            )
            .then((res) => {
              this.data = this.data.concat(res.data?.data);
              this.currentPage = res.data?.current_page;
              if (this.lastPage < this.nextPage) {
                this.nextPage++;
              }
            })
            .catch((err) => {
              console.log("Err", err);
            });
        }
      } catch (err) {
        console.log(err);
      }
    },
    updateStartTime(value) {
      this.selectedStartTime = value;
      this.nextPage = 1;
      this.getData();
    },
    updateEndTime(value) {
      this.selectedEndTime = value;
      this.nextPage = 1;
      this.getData();
    },
    checkBusyTime(value) {
      if (!value) return false;
    },
    selectPatient(patient) {
      /*
      this.nextPage = 1;

      this.filters.filters.patient = patient.name

      this.getData();
      */
    },
    searchPatient(query) {
      this.loading = true;
      this.patientSearchQuery = query;
      this.api
        .searchPatients(this.clinic.id, query)
        .then((res) => {
          this.patients = res.data;
        })
        .catch((err) => this.$toast.error(err.message))
        .finally(() => {
          this.loading = false;
        });
    },
    openWhatsApp(patient) {
      const whatsappNumber = patient.replace(/\D/g, "");

      if (whatsappNumber.length < 10) {
        this.$toast.warning("Número de telefone sem DDD");
        return;
      }

      window.open(`https://wa.me/55${whatsappNumber}`, "_blank");
    },
  },
};
</script>
<style scoped>
.no-padding {
  padding: unset !important;
}
.clear {
    font-weight: 500;
    cursor: pointer;
    display: flex;
    justify-content: end;
}
.icon {
  height: 24px;
  width: 24px;
}

.event-scheduled:nth-child(odd) {
  background: #f8f8fd !important;
}
</style>

<style lang="scss">
#event-item-card {
  div#infinite-list {
    overflow: scroll;
    overflow-x: hidden;
    height: 555px;
    padding-bottom: 26px;
  }
  .fix-close {
    right: 10px !important;
    top: 7px !important;
    z-index: 100 !important;
  }
  .multiselect__tags {
    width: 100% !important;
    border-radius: 8px !important;
    border: 1px solid var(--Neutral-300, #c6ceeb) !important;
    padding: 9px 0px;
  }
  .time-wrapper {
    margin-top: -24px;
  }
  .padding-fix {
    padding-right: 0px;
    padding-left: 9px;
  }
  .multiselect__single {
    color: var(--Type-Active, #525c7a) !important;
    /* Text / Small */
    font-family: Nunito Sans !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 150% !important;
    padding: 0px !important;
    text-align: center !important;
  }
  .multiselect__spinner {
    width: 46px !important;
    height: 35px !important;
  }
  .col-1 {
    display: flex;
    justify-content: center;
  }
  gap: 16px;
  .td-30 {
    color: #525c7a;
    font-family: Nunito Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 16px */
    justify-content: space-evenly;
    margin-top: 3px;
  }
  .statu-ret {
    width: 8px;
    height: auto;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid var(--Neutral-300, #c6ceeb);
  }
  .patient-name {
    color: #525c7a;
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
  }
  .fc-content {
    color: var(--type-active);
    white-space: nowrap;
    overflow: hidden;
    padding: 8px 25px 16px 16px !important;
  }

  .doc-info {
    color: var(--Type-Placeholder, #8696ac);
    font-family: Nunito Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 16px */
  }
  .pointer {
    cursor: pointer;
  }
  .help {
    cursor: help;
  }
  .content {
    background: white;
    border-radius: 8px;
    padding: 0px 25px 16px 16px;
    border: 1px;
    .blue {
      color: var(--Blue-500, #305bf2);
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 21px */
    }
    .light {
      color: #525c7a;
      font-family: Nunito Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
  }

  .scheduled {
    background: #ffffff;
    //background: #525C7A;
  }

  .confirmed {
    background: #046340;
  }

  .attend {
    background: #0b30b2;
  }

  .waiting {
    background: #80430b;
  }

  .happening {
    background: #ff6b00;
  }

  .examinating {
    background: #6c3350;
  }

  .dilating {
    background: #673270;
  }

  .in_pre_consultation {
    background: #008e75;
  }

  .pre_finished {
    background: #613f26;
  }

  .missed {
    background: #d82828;
  }

  .cancelled {
    background: #5266af;
  }

  .finished {
    background: #304388;
  }

  .fc-columns {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: top;
    align-self: stretch;
    height: 100%;

    .attendance-types {
      display: flex;
      flex-direction: row;
      align-items: top;
      gap: 4px;

      .exam {
        transform: scale(0.8331);
      }
    }

    .attendance-status {
      display: flex;
      flex-direction: row;
      align-items: top;
      gap: 4px;
      margin-left: 5px;
      svg {
        margin-bottom: 5px;
      }
    }

    .fc-icon {
      svg {
        fill: #525c7a;
      }

      &.icon-missed {
        svg {
          fill: #d82828;
        }
      }

      &.icon-cancelled {
        svg {
          fill: #ffffff;
        }
      }

      &.icon-cancelled_by_patient {
        svg {
          fill: #ffffff;
        }
      }

      &.icon-confirmed {
        svg {
          fill: #046340;
        }
      }

      &.icon-confirmed_by_wpp {
        svg {
          fill: #046340;
        }
      }

      &.icon-confirmed_by_patient {
        svg {
          fill: #046340;
        }
      }

      &.icon-attend {
        svg {
          fill: #0b30b2;
        }
      }

      &.icon-finished {
        svg {
          fill: #304388;
        }
      }

      &.icon-waiting {
        svg {
          fill: #80430b;
        }
      }

      &.icon-happening {
        svg {
          fill: #ff6b00;
        }
      }

      &.icon-examinating {
        svg {
          fill: #6c3350;
        }
      }

      &.icon-dilating {
        svg {
          fill: #673270;
        }
      }

      &.icon-pre_finished {
        svg {
          fill: #613f26;
        }
      }

      &.icon-scheduled {
        svg {
          fill: #525c7a;
        }
      }
    }
  }
}
</style>
